import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import FeesTable from "./FeesTable";

const FeesDeducted = ({
  selectedProfile
}) => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="color-primary">{t("Fees Deducted")}</h1>
      <FeesTable selectedProfile={selectedProfile} variant="INVESTOR" />
    </>
  );
};
export default memo(FeesDeducted);
