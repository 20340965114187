import PageHeader from "components/Forex/Common/PageHeader";
import Investors from "pages/Forex/Dashboard/SpPortal/Investors";
import SpAccountDetail from "pages/Forex/Dashboard/SpPortal/SpAccountDetail";
import SpJourney from "pages/Forex/Dashboard/SpPortal/SpJourney";
import { useEffect, useMemo } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import { getAccountDetailsInit } from "store/actions";
import { getMamProfilesInitAction } from "store/forex/mamProfile/actions";

const SpAccounts = () => {
  const dispatch = useDispatch();
  const { profiles, selectedMamAccount: selectedAccount } = useSelector(
    (state) => state.forex.mamProfile
  );

  const selectedTradingAccount = useMemo(() => {
    if (selectedAccount) {
      const filteredAccount = profiles.filter(
        (obj) => obj.masterTradingAccountId.login === selectedAccount.label
      )[0];
      return filteredAccount;
    }
    return null;
  }, [selectedAccount]);

  useEffect(() => {
    dispatch(getMamProfilesInitAction({ params: { type: "sp" } }));
  }, []);

  useEffect(() => {
    if (selectedTradingAccount) {
      dispatch(
        getAccountDetailsInit(selectedTradingAccount.masterTradingAccountId._id)
      );
    }
  }, [selectedTradingAccount]);

  return (
    <>
      <MetaTags>
        <title>Accounts</title>
      </MetaTags>
      <Container className="mt-5">
        <div className="forex-dashboard">
          <div className="page-content">
            <PageHeader title="Accounts"></PageHeader>
          </div>
          <div className="pt-3">
            <SpJourney />
          </div>
          <div className="pt-3">
            <SpAccountDetail
              selectedTradingAccount={selectedTradingAccount}
              profiles={profiles}
              operations={true}
            />
          </div>
          <div className="py-5">
            <Investors selectedTradingAccount={selectedTradingAccount} />
          </div>
        </div>
      </Container>
    </>

  );
};

export default SpAccounts;
