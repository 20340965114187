export const COUNTRIES = [
  {
    calling_code: 93,
    countryEn: "Afghanistan",
    countryAr: "أفغانستان",
    code: "AF"
  },
  {
    calling_code: 355,
    countryEn: "Albania",
    countryAr: "ألبانيا",
    code: "AL"
  },
  {
    calling_code: 213,
    countryEn: "Algeria",
    countryAr: "الجزائر",
    code: "DZ"
  },
  {
    calling_code: 1684,
    countryEn: "American Samoa",
    countryAr: "ساموا الأمريكية"
  },
  {
    calling_code: 376,
    countryEn: "Andorra",
    countryAr: "أندورا",
    code: "AD"
  },
  {
    calling_code: 244,
    countryEn: "Angola",
    countryAr: "أنجولا",
    code: "AO"
  },
  {
    calling_code: 1264,
    countryEn: "Anguilla",
    countryAr: "Anguilla"
  },
  {
    calling_code: 1268,
    countryEn: "Antigua and Barbuda",
    countryAr: "أنتيغوا وبربودا"
  },
  {
    calling_code: 54,
    countryEn: "Argentina",
    countryAr: "الأرجنتين",
    code: "AR"
  },
  {
    calling_code: 374,
    countryEn: "Armenia",
    countryAr: "أرمينيا",
    code: "AM"
  },
  {
    calling_code: 297,
    countryEn: "Aruba",
    countryAr: "آروبا",
    code: "AW"
  },
  // {
  //   calling_code: 61,
  //   countryEn: "Australia",
  //   countryAr: "أستراليا",
  //   code: "AU"
  // },
  {
    calling_code: 43,
    countryEn: "Austria",
    countryAr: "النمسا",
    code: "AT"
  },
  {
    calling_code: 994,
    countryEn: "Azerbaijan",
    countryAr: "أذربيجان",
    code: "AZ"
  },
  {
    calling_code: 1242,
    countryEn: "Bahamas",
    countryAr: "جزر البهاما"
  },
  {
    calling_code: 973,
    countryEn: "Bahrain",
    countryAr: "البحرين",
    code: "BH"
  },
  {
    calling_code: 880,
    countryEn: "Bangladesh",
    countryAr: "بنجلاديش",
    code: "BD"
  },
  {
    calling_code: 1246,
    countryEn: "Barbados",
    countryAr: "بربادوس"
  },
  {
    calling_code: 375,
    countryEn: "Belarus",
    countryAr: "روسيا البيضاء",
    code: "BY"
  },
  {
    calling_code: 32,
    countryEn: "Belgium",
    countryAr: "بلجيكا",
    code: "BE"
  },
  {
    calling_code: 501,
    countryEn: "Belize",
    countryAr: "بليز",
    code: "BZ"
  },
  {
    calling_code: 229,
    countryEn: "Benin",
    countryAr: "بنين",
    code: "BJ"
  },
  {
    calling_code: 1441,
    countryEn: "Bermuda",
    countryAr: "برمودا"
  },
  {
    calling_code: 975,
    countryEn: "Bhutan",
    countryAr: "بوتان",
    code: "BT"
  },
  {
    calling_code: 591,
    countryEn: "Bolivia",
    countryAr: "بوليفيا",
    code: "BO"
  },
  {
    calling_code: 387,
    countryEn: "Bosnia and Herzegovina",
    countryAr: "البوسنة والهرسك",
    code: "BA"
  },
  {
    calling_code: 267,
    countryEn: "Botswana",
    countryAr: "بتسوانا",
    code: "BW"
  },
  {
    calling_code: 55,
    countryEn: "Brazil",
    countryAr: "البرازيل",
    code: "BR"
  },
  {
    calling_code: 246,
    countryEn: "British Indian Ocean Territory",
    countryAr: "المحيط الهندي البريطاني",
    code: "IO"
  },
  {
    calling_code: 359,
    countryEn: "Bulgaria",
    countryAr: "بلغاريا",
    code: "BG"
  },
  {
    calling_code: 226,
    countryEn: "Burkina Faso",
    countryAr: "بوركينا فاسو",
    code: "BF"
  },
  {
    calling_code: 257,
    countryEn: "Burundi",
    countryAr: "بوروندي",
    code: "BI"
  },
  {
    calling_code: 855,
    countryEn: "Cambodia",
    countryAr: "كمبوديا",
    code: "KH"
  },
  {
    calling_code: 237,
    countryEn: "Cameroon",
    countryAr: "الكاميرون",
    code: "CM"
  },
  {
    calling_code: 1,
    countryEn: "Canada",
    countryAr: "كندا",
    code: "CA"
  },
  {
    calling_code: 1345,
    countryEn: "Cayman Islands",
    countryAr: "جزر كايمان"
  },
  {
    calling_code: 236,
    countryEn: "Central African Republic",
    countryAr: "جمهورية افريقيا الوسطى",
    code: "CF"
  },
  {
    calling_code: 235,
    countryEn: "Chad",
    countryAr: "تشاد",
    code: "TD"
  },
  {
    calling_code: 56,
    countryEn: "Chile",
    countryAr: "شيلي",
    code: "CL"
  },
  {
    calling_code: 86,
    countryEn: "China",
    countryAr: "الصين",
    code: "CN"
  },
  {
    calling_code: 61,
    countryEn: "Christmas Island",
    countryAr: "أستراليا",
    code: "AU"
  },
  {
    calling_code: 61,
    countryEn: "Cocos (Keeling) Islands",
    countryAr: "أستراليا",
    code: "AU"
  },
  {
    calling_code: 57,
    countryEn: "Colombia",
    countryAr: "كولومبيا",
    code: "CO"
  },
  {
    calling_code: 269,
    countryEn: "Comoros",
    countryAr: "جزر القمر",
    code: "KM"
  },
  {
    calling_code: 242,
    countryEn: "Congo",
    countryAr: "الكونغو - برازافيل",
    code: "CG"
  },
  {
    calling_code: 682,
    countryEn: "Cook Islands",
    countryAr: "جزر كوك",
    code: "CK"
  },
  {
    calling_code: 506,
    countryEn: "Costa Rica",
    countryAr: "كوستاريكا",
    code: "CR"
  },
  {
    calling_code: 385,
    countryEn: "Croatia",
    countryAr: "كرواتيا",
    code: "HR"
  },
  {
    calling_code: 53,
    countryEn: "Cuba",
    countryAr: "كوبا",
    code: "CU"
  },
  {
    calling_code: 357,
    countryEn: "Cyprus",
    countryAr: "قبرص",
    code: "CY"
  },
  {
    calling_code: 420,
    countryEn: "Czech Republic",
    countryAr: "جمهورية التشيك",
    code: "CZ"
  },
  {
    calling_code: 45,
    countryEn: "Denmark",
    countryAr: "الدانمرك",
    code: "DK"
  },
  {
    calling_code: 253,
    countryEn: "Djibouti",
    countryAr: "جيبوتي",
    code: "DJ"
  },
  {
    calling_code: 1767,
    countryEn: "Dominica",
    countryAr: "دومينيكا"
  },
  {
    calling_code: 1849,
    countryEn: "Dominican Republic",
    countryAr: "جمهورية الدومنيكان"
  },
  {
    calling_code: 670,
    countryEn: "East Timor",
    countryAr: "تيمور الشرقية",
    code: "TL"
  },
  {
    calling_code: 593,
    countryEn: "Ecuador",
    countryAr: "الاكوادور",
    code: "EC"
  },
  {
    calling_code: 20,
    countryEn: "Egypt",
    countryAr: "مصر",
    code: "EG"
  },
  {
    calling_code: 503,
    countryEn: "El Salvador",
    countryAr: "السلفادور",
    code: "SV"
  },
  {
    calling_code: 240,
    countryEn: "Equatorial Guinea",
    countryAr: "غينيا الاستوائية",
    code: "GQ"
  },
  {
    calling_code: 291,
    countryEn: "Eritrea",
    countryAr: "اريتريا",
    code: "ER"
  },
  {
    calling_code: 372,
    countryEn: "Estonia",
    countryAr: "استونيا",
    code: "EE"
  },
  {
    calling_code: 251,
    countryEn: "Ethiopia",
    countryAr: "اثيوبيا",
    code: "ET"
  },
  {
    calling_code: 500,
    countryEn: "Falkland Islands",
    countryAr: "جزر فوكلاند",
    code: "FK"
  },
  {
    calling_code: 298,
    countryEn: "Faroe Islands",
    countryAr: "جزر فارو",
    code: "FO"
  },
  {
    calling_code: 358,
    countryEn: "Finland",
    countryAr: "جزر أولان",
    code: "AX"
  },
  {
    calling_code: 33,
    countryEn: "France",
    countryAr: "فرنسا",
    code: "FR"
  },
  {
    calling_code: 594,
    countryEn: "French Guiana",
    countryAr: "غويانا",
    code: "GF"
  },
  {
    calling_code: 689,
    countryEn: "French Polynesia",
    countryAr: "بولينيزيا الفرنسية",
    code: "PF"
  },
  {
    calling_code: 241,
    countryEn: "Gabon",
    countryAr: "الجابون",
    code: "GA"
  },
  {
    calling_code: 220,
    countryEn: "Gambia",
    countryAr: "غامبيا",
    code: "GM"
  },
  {
    calling_code: 995,
    countryEn: "Georgia",
    countryAr: "جورجيا",
    code: "GE"
  },
  {
    calling_code: 49,
    countryEn: "Germany",
    countryAr: "ألمانيا",
    code: "DE"
  },
  {
    calling_code: 233,
    countryEn: "Ghana",
    countryAr: "غانا",
    code: "GH"
  },
  {
    calling_code: 350,
    countryEn: "Gibraltar",
    countryAr: "جبل طارق",
    code: "GI"
  },
  {
    calling_code: 30,
    countryEn: "Greece",
    countryAr: "اليونان",
    code: "GR"
  },
  {
    calling_code: 299,
    countryEn: "Greenland",
    countryAr: "جرينلاند",
    code: "GL"
  },
  {
    calling_code: 1473,
    countryEn: "Grenada",
    countryAr: "غرينادا"
  },
  {
    calling_code: 590,
    countryEn: "Guadeloupe",
    countryAr: "سان بارتيلمي",
    code: "BL"
  },
  {
    calling_code: 1671,
    countryEn: "Guam",
    countryAr: "غوام"
  },
  {
    calling_code: 502,
    countryEn: "Guatemala",
    countryAr: "جواتيمالا",
    code: "GT"
  },
  {
    calling_code: 224,
    countryEn: "Guinea",
    countryAr: "غينيا",
    code: "GN"
  },
  {
    calling_code: 245,
    countryEn: "Guinea-Bissau",
    countryAr: "غينيا بيساو",
    code: "GW"
  },
  {
    calling_code: 592,
    countryEn: "Guyana",
    countryAr: "غيانا"
  },
  {
    calling_code: 509,
    countryEn: "Haiti",
    countryAr: "هايتي",
    code: "HT"
  },
  {
    calling_code: 504,
    countryEn: "Honduras",
    countryAr: "هندوراس",
    code: "HN"
  },
  // {
  //   calling_code: 852,
  //   countryEn: "Hong Kong",
  //   countryAr: "هونج كونج الصينية",
  //   code: "HK"
  // },
  {
    calling_code: 36,
    countryEn: "Hungary",
    countryAr: "المجر",
    code: "HU"
  },
  {
    calling_code: 354,
    countryEn: "Iceland",
    countryAr: "أيسلندا",
    code: "IS"
  },
  {
    calling_code: 91,
    countryEn: "India",
    countryAr: "الهند",
    code: "IN"
  },
  {
    calling_code: 62,
    countryEn: "Indonesia",
    countryAr: "اندونيسيا",
    code: "ID"
  },
  // {
  //   calling_code: 98,
  //   countryEn: "Iran",
  //   countryAr: "ايران",
  //   code: "IR"
  // },
  {
    calling_code: 964,
    countryEn: "Iraq",
    countryAr: "العراق",
    code: "IQ"
  },
  {
    calling_code: 353,
    countryEn: "Ireland",
    countryAr: "أيرلندا",
    code: "IE"
  },
  // {
  //   calling_code: 972,
  //   countryEn: "Israel",
  //   countryAr: "اسرائيل",
  //   code: "IL"
  // },
  {
    calling_code: 39,
    countryEn: "Italy",
    countryAr: "ايطاليا",
    code: "IT"
  },
  {
    calling_code: 225,
    countryEn: "Ivory Coast",
    countryAr: "ساحل العاج",
    code: "CI"
  },
  {
    calling_code: 1876,
    countryEn: "Jamaica",
    countryAr: "جامايكا"
  },
  {
    calling_code: 81,
    countryEn: "Japan",
    countryAr: "اليابان",
    code: "JP"
  },
  {
    calling_code: 962,
    countryEn: "Jordan",
    countryAr: "الأردن",
    code: "JO"
  },
  {
    calling_code: 7,
    countryEn: "Kazakhstan",
    countryAr: "كازاخستان",
    code: "KZ"
  },
  {
    calling_code: 254,
    countryEn: "Kenya",
    countryAr: "كينيا",
    code: "KE"
  },
  {
    calling_code: 686,
    countryEn: "Kiribati",
    countryAr: "كيريباتي",
    code: "KI"
  },
  {
    calling_code: 965,
    countryEn: "Kuwait",
    countryAr: "الكويت",
    code: "KW"
  },
  {
    calling_code: 996,
    countryEn: "Kyrgyzstan",
    countryAr: "قرغيزستان",
    code: "KG"
  },
  {
    calling_code: 856,
    countryEn: "Laos",
    countryAr: "لاوس",
    code: "LA"
  },
  {
    calling_code: 371,
    countryEn: "Latvia",
    countryAr: "لاتفيا",
    code: "LV"
  },
  {
    calling_code: 961,
    countryEn: "Lebanon",
    countryAr: "لبنان",
    code: "LB"
  },
  {
    calling_code: 266,
    countryEn: "Lesotho",
    countryAr: "ليسوتو",
    code: "LS"
  },
  {
    calling_code: 231,
    countryEn: "Liberia",
    countryAr: "ليبيريا",
    code: "LR"
  },
  {
    calling_code: 281,
    countryEn: "Libya",
    countryAr: "ليبيا",
    code: "LY"
  },
  {
    calling_code: 423,
    countryEn: "Liechtenstein",
    countryAr: "ليختنشتاين",
    code: "LI"
  },
  {
    calling_code: 370,
    countryEn: "Lithuania",
    countryAr: "ليتوانيا",
    code: "LT"
  },
  {
    calling_code: 352,
    countryEn: "Luxembourg",
    countryAr: "لوكسمبورج",
    code: "LU"
  },
  {
    calling_code: 389,
    countryEn: "North Macedonia",
    countryAr: "مقدونيا",
    code: "MK"
  },
  {
    calling_code: 261,
    countryEn: "Madagascar",
    countryAr: "مدغشقر",
    code: "MG"
  },
  {
    calling_code: 265,
    countryEn: "Malawi",
    countryAr: "ملاوي",
    code: "MW"
  },
  {
    calling_code: 60,
    countryEn: "Malaysia",
    countryAr: "ماليزيا",
    code: "MY"
  },
  {
    calling_code: 960,
    countryEn: "Maldives",
    countryAr: "جزر الملديف",
    code: "MV"
  },
  {
    calling_code: 223,
    countryEn: "Mali",
    countryAr: "مالي",
    code: "ML"
  },
  {
    calling_code: 356,
    countryEn: "Malta",
    countryAr: "مالطا",
    code: "MT"
  },
  {
    calling_code: 692,
    countryEn: "Marshall Islands",
    countryAr: "جزر المارشال",
    code: "MH"
  },
  {
    calling_code: 596,
    countryEn: "Martinique",
    countryAr: "مارتينيك",
    code: "MQ"
  },
  {
    calling_code: 222,
    countryEn: "Mauritania",
    countryAr: "موريتانيا",
    code: "MR"
  },
  {
    calling_code: 230,
    countryEn: "Mauritius",
    countryAr: "موريشيوس",
    code: "MU"
  },
  {
    calling_code: 262,
    countryEn: "Mayotte",
    countryAr: "روينيون",
    code: "RE"
  },
  {
    calling_code: 52,
    countryEn: "Mexico",
    countryAr: "المكسيك",
    code: "MX"
  },
  {
    calling_code: 691,
    countryEn: "Micronesia, Federated States of",
    countryAr: "ميكرونيزيا",
    code: "FM"
  },
  {
    calling_code: 373,
    countryEn: "Moldova",
    countryAr: "مولدافيا",
    code: "MD"
  },
  {
    calling_code: 377,
    countryEn: "Monaco",
    countryAr: "موناكو",
    code: "MC"
  },
  {
    calling_code: 976,
    countryEn: "Mongolia",
    countryAr: "منغوليا",
    code: "MN"
  },
  {
    calling_code: 1664,
    countryEn: "Montserrat",
    countryAr: "مونتسيرات"
  },
  {
    calling_code: 212,
    countryEn: "Morocco",
    countryAr: "المغرب",
    code: "MA"
  },
  {
    calling_code: 258,
    countryEn: "Mozambique",
    countryAr: "موزمبيق",
    code: "MZ"
  },
  // {
  //   calling_code: 95,
  //   countryEn: "Myanmar",
  //   countryAr: "ميانمار",
  //   code: "MM"
  // },
  {
    calling_code: 264,
    countryEn: "Namibia",
    countryAr: "ناميبيا",
    code: "NA"
  },
  {
    calling_code: 674,
    countryEn: "Nauru",
    countryAr: "نورو",
    code: "NR"
  },
  {
    calling_code: 977,
    countryEn: "Nepal",
    countryAr: "نيبال",
    code: "NP"
  },
  {
    calling_code: 31,
    countryEn: "Netherlands",
    countryAr: "هولندا",
    code: "NL"
  },
  {
    calling_code: 687,
    countryEn: "New Caledonia",
    countryAr: "كاليدونيا الجديدة",
    code: "NC"
  },
  {
    calling_code: 64,
    countryEn: "New Zealand",
    countryAr: "نيوزيلاندا",
    code: "NZ"
  },
  {
    calling_code: 505,
    countryEn: "Nicaragua",
    countryAr: "نيكاراجوا",
    code: "NI"
  },
  {
    calling_code: 227,
    countryEn: "Niger",
    countryAr: "النيجر",
    code: "NE"
  },
  {
    calling_code: 234,
    countryEn: "Nigeria",
    countryAr: "نيجيريا",
    code: "NG"
  },
  {
    calling_code: 683,
    countryEn: "Niue",
    countryAr: "نيوي",
    code: "NU"
  },
  {
    calling_code: 672,
    countryEn: "Norfolk Island",
    countryAr: "القطب الجنوبي",
    code: "AQ"
  },
  {
    calling_code: 1670,
    countryEn: "Northern Mariana Islands",
    countryAr: "جزر مريانا الشمالية"
  },
  {
    calling_code: 47,
    countryEn: "Norway",
    countryAr: "جزيرة بوفيه",
    code: "BV"
  },
  {
    calling_code: 968,
    countryEn: "Oman",
    countryAr: "عمان",
    code: "OM"
  },
  {
    calling_code: 92,
    countryEn: "Pakistan",
    countryAr: "باكستان",
    code: "PK"
  },
  {
    calling_code: 680,
    countryEn: "Palau",
    countryAr: "بالاو",
    code: "PW"
  },
  {
    calling_code: 970,
    countryEn: "Palestine",
    countryAr: "فلسطين",
    code: "PS"
  },
  {
    calling_code: 507,
    countryEn: "Panama",
    countryAr: "بنما",
    code: "PA"
  },
  {
    calling_code: 675,
    countryEn: "Papua New Guinea",
    countryAr: "بابوا غينيا الجديدة",
    code: "PG"
  },
  {
    calling_code: 595,
    countryEn: "Paraguay",
    countryAr: "غيانا",
    code: "GY"
  },
  {
    calling_code: 51,
    countryEn: "Peru",
    countryAr: "بيرو",
    code: "PE"
  },
  {
    calling_code: 63,
    countryEn: "Philippines",
    countryAr: "الفيلبين",
    code: "PH"
  },
  // {
  //   calling_code: 48,
  //   countryEn: "Poland",
  //   countryAr: "بولندا",
  //   code: "PL"
  // },
  {
    calling_code: 351,
    countryEn: "Portugal",
    countryAr: "البرتغال",
    code: "PT"
  },
  {
    calling_code: 1939,
    countryEn: "Puerto Rico",
    countryAr: "Puerto Rico"
  },
  {
    calling_code: 974,
    countryEn: "Qatar",
    countryAr: "قطر",
    code: "QA"
  },
  {
    calling_code: 262,
    countryEn: "Reunion",
    countryAr: "روينيون",
    code: "RE"
  },
  {
    calling_code: 40,
    countryEn: "Romania",
    countryAr: "رومانيا",
    code: "RO"
  },
  // {
  //   calling_code: 7,
  //   countryEn: "Russian Federation",
  //   countryAr: "كازاخستان",
  //   code: "KZ"
  // },
  {
    calling_code: 250,
    countryEn: "Rwanda",
    countryAr: "رواندا",
    code: "RW"
  },
  {
    calling_code: 290,
    countryEn: "Saint Helena",
    countryAr: "سانت هيلنا",
    code: "SH"
  },
  {
    calling_code: 1869,
    countryEn: "Saint Kitts and Nevis",
    countryAr: "سانت كيتس ونيفيس"
  },
  {
    calling_code: 1758,
    countryEn: "Saint Lucia",
    countryAr: "القديسة لوسيا"
  },
  {
    calling_code: 508,
    countryEn: "Saint Pierre and Miquelon",
    countryAr: "سانت بيير وميكولون",
    code: "PM"
  },
  {
    calling_code: 1784,
    countryEn: "Saint Vincent and the Grenadines",
    countryAr: "سانت فنسنت وجزر غرينادين"
  },
  {
    calling_code: 685,
    countryEn: "Samoa",
    countryAr: "ساموا",
    code: "WS"
  },
  {
    calling_code: 378,
    countryEn: "San Marino",
    countryAr: "سان مارينو",
    code: "SM"
  },
  {
    calling_code: 239,
    countryEn: "Sao Tome and Principe",
    countryAr: "ساو تومي وبرينسيبي",
    code: "ST"
  },
  {
    calling_code: 966,
    countryEn: "Saudi Arabia",
    countryAr: "المملكة العربية السعودية",
    code: "SA"
  },
  {
    calling_code: 221,
    countryEn: "Senegal",
    countryAr: "السنغال",
    code: "SN"
  },
  {
    calling_code: 248,
    countryEn: "Seychelles",
    countryAr: "سيشل",
    code: "SC"
  },
  {
    calling_code: 232,
    countryEn: "Sierra Leone",
    countryAr: "سيراليون",
    code: "SL"
  },
  {
    calling_code: 65,
    countryEn: "Singapore",
    countryAr: "سنغافورة",
    code: "SG"
  },
  {
    calling_code: 421,
    countryEn: "Slovakia",
    countryAr: "سلوفاكيا",
    code: "SK"
  },
  {
    calling_code: 386,
    countryEn: "Slovenia",
    countryAr: "سلوفينيا",
    code: "SI"
  },
  {
    calling_code: 677,
    countryEn: "Solomon Islands",
    countryAr: "جزر سليمان",
    code: "SB"
  },
  {
    calling_code: 252,
    countryEn: "Somalia",
    countryAr: "الصومال",
    code: "SO"
  },
  {
    calling_code: 27,
    countryEn: "South Africa",
    countryAr: "جمهورية جنوب افريقيا",
    code: "ZA"
  },
  {
    calling_code: 500,
    countryEn: "South Georgia and the South Sandwich Islands",
    countryAr: "جزر فوكلاند",
    code: "FK"
  },
  {
    calling_code: 211,
    countryEn: "South Sudan",
    countryAr: "جنوب السودان",
    code: "SS"
  },
  {
    calling_code: 34,
    countryEn: "Spain",
    countryAr: "أسبانيا",
    code: "ES"
  },
  {
    calling_code: 94,
    countryEn: "Sri Lanka",
    countryAr: "سيريلانكا",
    code: "LK"
  },
  {
    calling_code: 249,
    countryEn: "Sudan",
    countryAr: "السودان",
    code: "SD"
  },
  {
    calling_code: 597,
    countryEn: "Suriname",
    countryAr: "سورينام",
    code: "SR"
  },
  {
    calling_code: 268,
    countryEn: "Swaziland",
    countryAr: "سوازيلاند",
    code: "SZ"
  },
  {
    calling_code: 46,
    countryEn: "Sweden",
    countryAr: "السويد",
    code: "SE"
  },
  {
    calling_code: 41,
    countryEn: "Switzerland",
    countryAr: "سويسرا",
    code: "CH"
  },
  // {
  //   calling_code: 963,
  //   countryEn: "Syria",
  //   countryAr: "سوريا",
  //   code: "SY"
  // },
  {
    calling_code: 992,
    countryEn: "Tajikistan",
    countryAr: "طاجكستان",
    code: "TJ"
  },
  {
    calling_code: 255,
    countryEn: "Tanzania",
    countryAr: "تانزانيا",
    code: "TZ"
  },
  {
    calling_code: 66,
    countryEn: "Thailand",
    countryAr: "تايلند",
    code: "TH"
  },
  {
    calling_code: 243,
    countryEn: "The Democratic Republic of Congo",
    countryAr: "جمهورية الكونغو الديمقراطية",
    code: "CD"
  },
  {
    calling_code: 228,
    countryEn: "Togo",
    countryAr: "توجو",
    code: "TG"
  },
  {
    calling_code: 690,
    countryEn: "Tokelau",
    countryAr: "توكيلو",
    code: "TK"
  },
  {
    calling_code: 676,
    countryEn: "Tonga",
    countryAr: "تونجا",
    code: "TO"
  },
  {
    calling_code: 1868,
    countryEn: "Trinidad and Tobago",
    countryAr: "ترينداد وتوباغو"
  },
  {
    calling_code: 216,
    countryEn: "Tunisia",
    countryAr: "تونس",
    code: "TN"
  },
  {
    calling_code: 90,
    countryEn: "Turkey",
    countryAr: "تركيا",
    code: "TR"
  },
  {
    calling_code: 993,
    countryEn: "Turkmenistan",
    countryAr: "تركمانستان",
    code: "TM"
  },
  {
    calling_code: 1649,
    countryEn: "Turks and Caicos Islands",
    countryAr: "جزر تركس وكايكوس"
  },
  {
    calling_code: 688,
    countryEn: "Tuvalu",
    countryAr: "توفالو",
    code: "TV"
  },
  {
    calling_code: 256,
    countryEn: "Uganda",
    countryAr: "أوغندا",
    code: "UG"
  },
  {
    calling_code: 380,
    countryEn: "Ukraine",
    countryAr: "أوكرانيا",
    code: "UA"
  },
  {
    calling_code: 971,
    countryEn: "United Arab Emirates",
    countryAr: "الامارات العربية المتحدة",
    code: "AE"
  },
  {
    calling_code: 44,
    countryEn: "United Kingdom",
    countryAr: "المملكة المتحدة",
    code: "GB"
  },
  // {
  //   calling_code: 1,
  //   countryEn: "United States",
  //   countryAr: "أنتيجوا وبربودا",
  //   code: "AG"
  // },
  {
    calling_code: 598,
    countryEn: "Uruguay",
    countryAr: "أورجواي",
    code: "UY"
  },
  {
    calling_code: 998,
    countryEn: "Uzbekistan",
    countryAr: "أوزبكستان",
    code: "UZ"
  },
  {
    calling_code: 678,
    countryEn: "Vanuatu",
    countryAr: "فانواتو",
    code: "VU"
  },
  {
    calling_code: 58,
    countryEn: "Venezuela",
    countryAr: "فنزويلا",
    code: "VE"
  },
  {
    calling_code: 84,
    countryEn: "Vietnam",
    countryAr: "فيتنام",
    code: "VN"
  },
  {
    calling_code: 681,
    countryEn: "Wallis and Futuna",
    countryAr: "جزر والس وفوتونا",
    code: "WF"
  },
  {
    calling_code: 967,
    countryEn: "Yemen",
    countryAr: "اليمن",
    code: "YE"
  },
  {
    calling_code: 260,
    countryEn: "Zambia",
    countryAr: "زامبيا",
    code: "ZM"
  },
  {
    calling_code: 263,
    countryEn: "Zimbabwe",
    countryAr: "زيمبابوي",
    code: "ZW"
  }
];

export const INT_COUNTRIES = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "British Indian Ocean Territory",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "Indonesia",
  "Iraq",
  "Ireland",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "North Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Rwanda",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "The Democratic Republic of Congo",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Wallis and Futuna",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];