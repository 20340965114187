
import * as axiosHelper from "./api_helper";
import qs from "qs";

export async function getAffiliateAPI(payload) {
  const data = await axiosHelper.get(`/affiliate?${qs.stringify(payload)}`);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data;
}
