import { useTranslation } from "react-i18next";

const PendingRequestBadge = ({ text }) => {
  const { t } = useTranslation();

  return (
    <span className={"w-md-50 m-auto py-0 alert alert-warning my-2"}>{t(text)}</span>
  );
};

export default PendingRequestBadge;
