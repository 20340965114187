import { AvField, AvForm } from "availity-reactstrap-validation";
import AvFieldSelecvt from "components/Common/AvFieldSelect";
import CardWrapper from "components/Common/CardWrapper";
import TableLoader from "components/Common/TableLoader";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Tbody,
  Thead,
  Tr,
  Table,
  Td,
  Th
} from "react-super-responsive-table";
import moment from "moment";
import {
  Button,
  Col,
  Container,
  Row
} from "reactstrap";
import { getAffiliateAPI } from "apis/affiliate";
import CustomPagination from "components/Common/CustomPagination";
import { getColumns } from "./columns";

export default function AffiliateReport() {
  const [columns, setColumns] = React.useState(getColumns());
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [sizePerPage, setSizePerPage] = React.useState(10);
  const [pagination, setPagination] = React.useState({});
  const [dateFrom, setDateFrom] = React.useState();
  const [dateTo, setDateTo] = React.useState();

  const [type, setType] = React.useState("commission");
  const { t } = useTranslation();
  const getAffiliate = (payload) => {
    setLoading(true);
    getAffiliateAPI(payload).then((res) => {
      const { result } = res;
      const { docs: rows, ...pagination } = result;
      setRows(rows || []);
      setPagination(pagination);
      setColumns(getColumns(type));
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    const payload = {
      dateFrom: moment().subtract(1, "months").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
      type,
      page: 1,
      limit: sizePerPage,
    };
    getAffiliate(payload);
  }, [sizePerPage]);


  const handleSubmit = () => {
    const payload = {
      dateFrom,
      dateTo,
      type,
      page: 1,
      limit: sizePerPage,
    };
    getAffiliate(payload);
  };
  return (
    <Container>
      <CardWrapper className="mt-3 px-5 py-4 glass-card shadow">
        <Row className="my-2">
          <Col xs={12} md={6} className="text-center text-md-start">
            <h4 className="color-primary">{t("Affiliate Report")}</h4>
          </Col>
        </Row>
        <AvForm onValidSubmit={(e, v)=> handleSubmit(e, v)}>
          <Row>
            <Col xs="12" md="3" lg="2">
              <AvField type="date" name="dateFrom" label={t("Date From")} className="mt-1 mb-2"
                onChange={(e) => { setDateFrom(e.target.value) }}
                max={dateTo ? moment(dateTo).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                required />
            </Col>
            <Col xs="12" md="3" lg="2">
              <AvField type="date" name="dateTo" label={t("Date To")} className="mt-1 mb-2"
                onChange={(e) => { setDateTo(e.target.value) }}
                max={moment().add(1, "days").format("YYYY-MM-DD")}
                min={dateFrom && moment(dateFrom).format("YYYY-MM-DD")}
                required />
            </Col>
            <Col xs={12} md={6} className="text-center text-md-start">
              <AvFieldSelecvt
                label="Select Type"
                name="type"
                options={[{
                  label: "Commission",
                  value: "commission"
                }, {
                  label: "Profit Share",
                  value: "profit"
                }
                ]}
                onChange={(e) => {
                  setType(e);
                }}
              />
            </Col>
            <Col>
              <Button type="submit" className="border-0 color-bg-btn mt-4 w-100">{t("Submit")}</Button>
            </Col>
          </Row>
        </AvForm>
        <Table borderless responsive className="text-center mb-0 my-3">
          <Thead>
            <Tr>
              {columns?.map((column, index) => (
                <Th data-priority={index} key={index} className="color-primary text-center"> 
                  {column.text}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {loading && <TableLoader colSpan={4} />}
            {!loading && rows.map((row, rowIndex) => (
              <React.Fragment key={`$row-${rowIndex}`}>
                <Tr className="text-center shadow" style={{
                  borderRadius: "4px",
                }}>
                  {columns.map((column, index) => {
                    return <Td key={`${rowIndex}-${index}`} style={{
                      verticalAlign: "middle",
                      height: 40,
                      padding: 10,
                    }}>
                      {column.formatter
                        ? column.formatter(row, rowIndex)
                        : row[column.dataField]}
                    </Td>;
                  })}
                </Tr>
                <Tr className="empty-row"></Tr>
              </React.Fragment>    
            ))}
            {!loading && rows.length === 0 && (
              <Tr>
                <Td colSpan={"100%"} className="text-center">{t("There are no records")}</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
        {
          !loading && rows.length > 0 && (
            <div className="mt-4">
              <CustomPagination
                {...pagination}
                setSizePerPage={setSizePerPage}
                sizePerPage={sizePerPage}
                onChange={(page, limit) => getAffiliate({
                  page,
                  limit,
                  dateFrom,
                  dateTo,
                  type,
                })}
              />
            </div>
          )
        }
      </CardWrapper>
    </Container>
  );
}
