import * as axiosHelper from "../api_helper";
import qs from "qs";

export const getCPClientAccountActivitiesAPI = async (params) => {
  try {
    const type = params.type;
    const tradingAccountId = params.tradingAccountId;
    delete params.type;
    delete params.tradingAccountId;
    return await axiosHelper.get(
      `/accounts/${tradingAccountId}/${type}?${qs.stringify(params)}`
    );
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getCpTransactionsAPI = async (params) => {
  try {
    let url = `fxtransactions/${
      params.type === "DEPOSIT" ? "investor-deposits" : "investor-withdrawals"
    }?`;

    url += qs.stringify(params);
    return await axiosHelper.get(url);
  } catch (error) {
    throw new Error(error.message);
  }
};
