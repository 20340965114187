import FeesTable from "pages/Forex/Dashboard/InvestorPortal/FeesTable";
import React from "react";
import { useTranslation } from "react-i18next";

const FeesCollected = ({
  selectedProfile
}) => {
  const { t } = useTranslation();
  return (
    <div className="py-5">
      <h1 className="color-primary">{t("Fees Collected")}</h1>
      <FeesTable selectedProfile={selectedProfile} variant="MASTER" />
    </div>
  );
};

export default FeesCollected;
