import { getProfitGraphAPI5 } from "apis/forex/accounts";
import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { Row, Spinner } from "reactstrap";

export default function ProfileBasicStats({ profile }) {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([25, 54, 14, 8, 20]);
  const { layoutMode } = useSelector((state) => state.Layout);

  useEffect(() => {
    setLoading(true);
    getProfitGraphAPI5({ 
      _id: profile?.masterTradingAccountId?._id,
    }).then((res) => {
      if (res?.isSuccess) {
        setData(res?.result?.map((item) => item?.profit) ?? 0);
      } else {
        throw new Error("Error while fetching profit graph.");
      }
    }).catch((err) => {
      setData([]);
    }).finally(() => {
      setLoading(false);
    });
  }, [profile]);

  if (loading) {
    return <div>
      <Spinner />
    </div>;
  }
  if (!data.length) {
    return <></>;
  }

  return (
    <Row className="sparkboxes">
      <ReactApexChart options={{
        chart: {
          id: `sparkline${profile._id}`,
          type: "line",
          height: 140,
          sparkline: {
            enabled: true
          },
        },
      
        stroke: {
          curve: "smooth"
        },
        tooltip: {
          x: {
            show: false
          },
          y: {
            show: false,
          }
        },
        colors: layoutMode === "light" ? ["#395B77"] : ["#F89622"]
      }} series={[{
        name: "Profit",
        data,
      }]} type="line" height={150} />
    </Row>
  );
}
