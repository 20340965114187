import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
// eslint-disable-next-line object-curly-newline
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { postUnlinkReqInit } from "store/actions";

const UnlinkMasterModal = ({
  isOpen,
  toggle,
  selectedAccount,
  selectedInvestorAccount,
  profileId
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(
      postUnlinkReqInit({
        profileId,
        investorId: selectedInvestorAccount?._id,
      })
    );
  };

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {t("Unlink Master")}
      </ModalHeader>
      <ModalBody>
        <p>
          {t("You will unlink from Profile")} <b>{selectedAccount?.login} </b>
          {t("for Account ")}
          <b> {selectedInvestorAccount?.login} </b> .
          {t("Are you sure you want to continue this?")}
        </p>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex gap-2 mx-auto">
          <Button onClick={toggle}>{t("Cancel")}</Button>
          <Button
            onClick={handleSubmit}
            className="btn btn-success"
            type="submit"
          >
            {t("Unlink")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default UnlinkMasterModal;
