
import CardWrapper from "components/Common/CardWrapper";
import React from "react";
import { useTranslation } from "react-i18next";
import { Thead } from "react-super-responsive-table";
// eslint-disable-next-line object-curly-newline
import { Row, Table } from "reactstrap";

// this is for table heads
const THs = [
  "Account", "Currency", "Balance", "Credit", "Equity", "Margin", "Margin Level", "Free Margin"
];
const Investors = (props) => {
  const { t } = useTranslation();
  const { selectedTradingAccount } = props;
  return (
    <>
      <h1 className="color-primary">{t("Investors")}</h1>
      <CardWrapper className="mt-4 p-4 glass-card">
        <div className="table-wrapper">
          <Table
            borderless
            responsive
            hover
            className="table-content"
          >
            <Thead className="table-head">
              <tr>
                {THs.map(th => <th className="table-head-item" key={th}>{t(th)}</th>)}
              </tr>
            </Thead>
            <tbody className="table-body">
              {selectedTradingAccount && !selectedTradingAccount.slaves ? (
                <tr>
                  <td colSpan="100%" className="table-body-item">
                    {t("You currently don't have any accounts.")}
                  </td>
                </tr>
              ) : (
                selectedTradingAccount &&
                selectedTradingAccount.slaves.map((slave) => (
                  <tr key={slave.login}>
                    <td className="table-body-item">{slave.login}</td>
                    <td className="table-body-item">{slave.currency}</td>
                    <td className="table-body-item">{slave.Balance}</td>
                    <td className="table-body-item">{slave.Credit}</td>
                    <td className="table-body-item">{slave.Equity}</td>
                    <td className="table-body-item">{slave.Margin}</td>
                    <td className="table-body-item">{slave.MarginLevel}</td>
                    <td className="table-body-item">{slave.MarginFree}</td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      </CardWrapper>
    </>
  );
};

export default Investors;
