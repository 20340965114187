/* eslint-disable object-curly-newline */
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_CP_MASTER_DEPOSITS_START,
  GET_CP_MASTER_DEPOSITS_SUCCESS,
  GET_CP_INVESTOR_WITHDRAWALS_SUCCESS,
  GET_CP_FEETRADING_WITHDRAWALS_SUCCESS,
  GET_CP_FEETRADING_WITHDRAWALS_START,
  GET_CP_INVESTOR_DEPOSITS_START,
  GET_CP_INVESTOR_WITHDRAWALS_START,
  GET_CP_INVESTOR_DEPOSITS_SUCCESS,
} from "./actionTypes";
import { getCpTransactionsAPI } from "apis/forex/cp";

function* getTransactions({ payload }) {
  const resp = yield call(getCpTransactionsAPI, payload.params);
  const { result } = resp;
  if (
    payload.variant === "MasterDeposit" &&
    payload.params.type === "DEPOSIT"
  ) {
    yield put({
      type: GET_CP_MASTER_DEPOSITS_SUCCESS,
      payload: result,
    });
  }
  if (
    payload.variant === "InvestorDeposit" &&
    payload.params.type === "DEPOSIT"
  ) {
    yield put({
      type: GET_CP_INVESTOR_DEPOSITS_SUCCESS,
      payload: result,
    });
  }
  if (
    payload.variant === "InvestorWithdraw" &&
    payload.params.type === "WITHDRAW"
  )
    yield put({
      type: GET_CP_INVESTOR_WITHDRAWALS_SUCCESS,
      payload: result,
    });
  if (
    payload.variant === "FeeTradingWithdraw" &&
    payload.params.type === "WITHDRAW"
  )
    yield put({
      type: GET_CP_FEETRADING_WITHDRAWALS_SUCCESS,
      payload: result,
    });
}

function* transactionsSaga() {
  yield takeLatest(GET_CP_INVESTOR_WITHDRAWALS_START, getTransactions);
  yield takeLatest(GET_CP_INVESTOR_DEPOSITS_START, getTransactions);
  yield takeLatest(GET_CP_FEETRADING_WITHDRAWALS_START, getTransactions);
  yield takeLatest(GET_CP_MASTER_DEPOSITS_START, getTransactions);
}

export default transactionsSaga;
